<template>
  <div class="about">
    <h1>This is an about page</h1><router-link to="/">Home</router-link>
  </div>
</template>
<style scoped>
	.about{
		height: 100vh;
	}
	body, html{
		overflow: hidden;
	}
</style>
