<template>
	<div class="spacer">
		<div id="innerNav" class="navigation section" :class="{ 'onScroll': !view.topOfPage}">
			<img class="logo" :src="logo" />
			<div class="mobile nav">
				<i class="fas fa-bars" v-if="isHidden" v-on:click="isHidden = false;"></i>
				<router-link v-if="!isHidden" to="/">Home</router-link>
				<router-link v-if="!isHidden" to="/services">Services</router-link>
				<a v-if="!isHidden" href="mailto:info@straightpathit.com">Contact Us</a>
				<a v-if="!isHidden" href="tel:832-271-1151">(832) 271-1151</a>
			</div>
			<div class="pc nav">
				<router-link to="/">Home</router-link>
				<router-link to="/services">Services</router-link>
				<a href="mailto:info@straightpathit.com">Contact Us</a>
				<a href="tel:832-271-1151">(832) 271-1151</a>
				<!-- <a href="#"><i class="fa fa-search"></i></a> -->
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'innerNav',
	data(){
		return{
			view: {
				topOfPage: true
			},
			isHidden: true,
			logo: require('@/assets/logo2.png')
		}
    },
	methods: {
		handleScroll(){
			// console.log(window.pageYOffset);
			if(window.pageYOffset > 50){
				if(this.view.topOfPage){
					this.view.topOfPage = false;
				} 
			}
			else {
				if(!this.view.topOfPage){
					this.view.topOfPage = true; 
				}
			}
		}
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll)
	},
}
</script>
<style lang="scss">
	@import '../assets/style.scss';
	@media only screen and (min-width: 64em){
		#innerNav{
			position: fixed;
			top: 0px; 
			left: 0px;
			right: 0px;
			z-index: 99;

			.logo{
				width: 500px;
				height: auto;
			}
			transition: all 250ms ease;
			*{
				transition: all 250ms ease-out;
			}
		}
		.onScroll .logo{
			width: 250px !important;
		}
		.spacer{
			min-height: 150px;
		}
	}
</style>