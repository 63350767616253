<!-- eslint disable -->
<template>
	<div>
		<div class="services">
		<innerNav></innerNav>
				<center><h1 id="title">Services</h1></center>
			<div class="main">
				<div v-for="item, j in items" :key="j" class="holder" data-aos="fade-up" data-aos-once="true" :data-aos-delay="100 * j">
					<router-link :to="{ path: '/service/' + item.url }">
						<h1>{{ item.name }}</h1>
						<div style="text-align: center;">
							<span v-for="blurb, i in item.blurb" :key="i" class="item">
								{{ blurb }}
								<span v-if="i < item.blurb.length - 1 && (i + 1) % 2 != 0" class="dot"> •  </span>
								<br v-if="(i + 1) % 2 == 0 && i != 0" />
							</span>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>
<style scoped lang="scss">
.navigation.section{
	/* background-image: none; */
}
.services.component{
	overflow-x: hidden;
}
.main{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 0%;
	padding-bottom: 5%;

	.holder{
		border: 1px solid #5c7d8a;
		background-color: rgba(255, 255, 255, 0.75);
		flex: 0 0 calc(33%);
		margin: 10px;
		padding: 2.5%;
		padding-top: 1.5%;

		&:last-child{
			border-bottom-width: 0;
		}
		&:hover{
			h1{
				color: #13bff1;
			}
			background-color: rgba(255, 255, 255, 1);
			border-radius: 5px;
		}
	}

	h1{
		text-align: center;
		color: #5c7d8a;
		margin-bottom: 4px;
	}

	.item{
		font-size: 1.2rem;
		line-height: 1.5;
		orphans: 200;
		color: black;
	}
	.dot{
		color: #5c7d8a;
		margin: 0 1vw;	
	}
	a{
		text-decoration: none;
	}
}
</style>
<style lang="scss">
	@import '../assets/style.scss';
</style>
<script>
// @ is an alias to /src
import innerNav from '@/components/innerNav.vue'
import bottom from '@/components/bottom.vue'

export default {
	name: 'Services',
	title: "Straight Path IT Management • Services",
	data(){ return {
		items: [
			{ 
				"name": "IT Managed Services", 
				"url": "it-managed-services",
				"blurb": [
					"IT Managed Services",
					"Remote Management and Monitoring",
					"On-Site Support",
					"Patch Management",
					"Data Protection",
					"Network Security",
					"Disaster Prevention"
				]
			},
			{
				"name": "Professional Services",
				"url": "professional-services",
				"blurb": [
					"Assessments",
					"Infrastructure Design and Migration",
					"Server Virtualization",
					"Email Migration",
					"Disaster Mitigation and Recovery",
					"Project-Based Services",
					"Office Relocations",
					"System Upgrades"
				]
			},
			{
				"name": "Cloud Services",
				"url": "cloud-services",
				"blurb": [ 
					"Microsoft 365 (formerly Office 365)", 
					"Microsoft Azure", 
					"Amazon AWS", 
					"Google Cloud Platform", 
					"Google G Suite"
				]
			},
			{
				"name": "Business Services",
				"url": "business-services",
				"blurb": [
					"vCIO (Virtual CIO)",
					"IT Governance",
					"IT Budgeting",
					"Policy and Procedure Creation",
					"Vendor Management",
					"Staff Augmentation",
					"User and Staff Education"
				]
			},
			{
				"name": "Web Services",
				"url": "web-services",
				"blurb": [
					"Business Websites",
					"Cloud Hosting and Migrations",
					"eCommerce",
					"Social Media Integrations",
					"CMS (Content Management System) Upgrades and Consultations"
				]
			}
		]
	}},
	components: {
		innerNav,
		bottom
	}
}
</script>
